import { Segment } from 'semantic-ui-react';
import { marked } from 'marked';
import React from 'react';
import "./index.css"


const OtherTools = () => {

  return (
  <div style={{marginLeft: "16%", marginRight:"16%"}}>
      <Segment className="no-border-segment">
        <h1 className="text-center">Introducing our other product tools</h1>
        <h3>From the Desk of an Indie Developer: Introducing aiundetect.com and aihumanize.io</h3>
        <p>
          Hello, fellow tech enthusiasts and content creators! I'm thrilled to share with you my journey as an independent developer and introduce two projects that have been my passion for the past few years: aiundetect.com and aihumanize.io. These platforms were born out of a desire to bridge the gap between AI-generated content and the unmistakable touch of human creativity. Let's dive into what each of these projects offers and how they can revolutionize your content creation process.
        </p>
        <h3><a href="https://aiundetect.com/">aiundetect.com: Mastering the Art of Undetectable AI</a></h3>
        <p>
          In an era where AI-generated content is becoming increasingly prevalent, the challenge of making such content undetectable to AI detection tools has never been more significant. That's where aiundetect.com comes in. This platform is designed to analyze your AI-generated text and ensure it passes as human-written, making it invaluable for academics, professionals, and content creators who aim for authenticity.
        </p>
        <p>
          Key Features:
          <br/>
          AI Detection Analysis: Utilizing a variety of detection tools, including TURNITIN (premium), GPTZERO, and COPYLEAK, we provide a comprehensive assessment of your content's detectability.
          <br/>
          Humanize Your Content: For texts flagged by detection tools, our human rewriting services offer a solution to ensure your content remains undetectable and retains a human touch.
          <br/>
          Multilingual Support: Understanding the global nature of content creation, we've made our services available in multiple languages.
          <br/>
          Accessibility: Start using our platform without any hurdles like mandatory logins or credit card requirements.
        </p>
        <h3><a href="https://aihumanize.io/"> aihumanize.io: Bringing AI  to Human Content</a></h3>
        <p>
          While aiundetect.com focuses on making AI-generated content undetectable, aihumanize.io takes a step further by transforming AI text into something that feels genuinely human. This platform is perfect for those who not only want to bypass AI detectors but also aim to enrich their content with the warmth and nuances of human writing.
        </p>
        <p>
          Key Features:
          <br/>
          Advanced Humanization Models: Choose between Basic and Advanced models to convert your AI-generated text into original, human-like content.
          Comprehensive Detection: Employ state-of-the-art AI detectors like GPTZERO, COPYLEAK, and CHATGPT to ensure your content's authenticity.
          Flexible Subscription Plans: Whether you're a casual user or require heavy usage, we have plans tailored to your needs.
          Versatile Use Cases: Ideal for a wide range of users, from students to bloggers, looking to enhance their content's quality and SEO friendliness.
          <br/>
          Why I Built These Platforms
          <br/>
          As an indie developer, my goal was to create tools that not only solve a problem but also empower users to produce content that stands out for its quality and authenticity. With over 300,000 users trusting our platforms, I'm proud to say that we're making significant strides toward achieving that goal. These projects represent my commitment to innovation, user-centric design, and the belief that technology should augment human creativity, not replace it.
          <br/><br/>
          Whether you're looking to safeguard your academic integrity, maintain the authenticity of professional documents, or simply create more engaging and SEO-friendly blog content, aiundetect.com and aihumanize.io are here to help. They are more than just tools; they are partners in your creative process.
          <br/><br/>
          In addition to the above two tools, we also provide other tools tailored for IT workers: <a href="https://codetoolshub.com/">codetoolshub</a>
          <br/><br/>
          Thank you for taking the time to learn about my projects. I invite you to explore these platforms and join me in redefining the boundaries of AI-assisted content creation. Together, let's create content that resonates, engages, and, most importantly, remains undeniably human.
        </p>
      </Segment>
  </div>
  )
};

export default OtherTools